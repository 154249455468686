#navbarColor {
    background-color: rgb(236, 251, 248) !important;
    padding: 0 !important;
}

/* .brandLogo {
    font-size: 1.5rem;
    font-weight: 500;
} */

.logoDoctor img {
    height: 80px;
    width: 100px;
    object-fit: cover;
}

.nav-item {
    margin: 0 0.6rem;
    font-size: 1.2rem;
}

a {
    font-weight: 500 !important;
}

a:hover {
    color: rgb(61, 203, 182) !important;
}

.navAppointment {
    color: white;
    font-weight: 400 !important;
    font-size: medium !important;
    background-color: rgb(14, 113, 194);
    border: 0 !important;
    outline: 0 !important;
}

.navAppointment:hover {
    color: white;
    font-size: medium !important;
    background-color: rgb(4, 137, 247);
}