.contact {
    background-color: white !important;
    height: 100%;
    width: 100%;
}


.glassEffect {
    background-color: rgb(178, 233, 240) !important;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin: 10px 0;
}

.navAppointment:hover {
    color: white !important;
}



.contactHeading h3 {
    font-weight: 600;
}

.contactSubHeading {
    font-weight: 400;
}