.Achivement {
    background-color: rgb(126, 163, 226);
    height: auto;
    width: 100%;
    padding: 0;

}

.achievementCat {
    margin: auto;
    margin-top: 50px;
    padding: 0;
}

.achievementCat h5 {
    font-weight: 700;
    color: white;
    font-size: 2rem;
}

.achievementCat p {
    font-weight: 600;
    color: white;
    font-size: 1rem;
    opacity: 0.7;

}

@media screen and (max-width:575px) {
    .achievementCat h5 {
        font-size: 2rem;
    }

    .Achivement {
        height: 20vh;

    }

    .achievementCat {
        margin: auto;
        margin-top: initial;

    }

    .achievementCat p {
        font-weight: 500;
        color: white;
        font-size: 0.7rem;
        opacity: 0.9;

    }
}

@media screen and (max-width:393px) {
    .achievementCat h5 {
        font-size: 1.5rem;
    }

    .achievementCat p {
        font-size: 0.6rem;

    }

    .Achivement {
        height: auto;

    }
}

@media screen and (max-width:412px) and (min-height:915px) {
    .Achivement {
        background-color: rgb(126, 163, 226);
        height: 10vh;
        width: 100%;
        padding: 0;

    }
}

@media screen and (max-width:430px) and (min-height:930px) {
    .Achivement {
        background-color: rgb(126, 163, 226);
        height: 10vh;
        width: 100%;
        padding: 0;

    }
}

@media screen and (max-width:414px) and (min-height:896px) {
    .Achivement {
        background-color: rgb(126, 163, 226);
        height: 10vh;
        width: 100%;
        padding: 0;

    }
}