.Facilities {
    height: auto;
    width: 100%;
    background-color: rgb(246, 252, 252);
}

.FacilitiesBtn button {
    color: rgb(159, 216, 204);
    font-weight: 700;
    outline: 2px solid rgb(159, 216, 204);

}

.boxFacilities {
    background-color: rgb(201, 248, 242);
    border-radius: 8px;
}

.sessionColor h5 {
    color: rgb(31, 87, 162);
}

.logoProcessFa {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: white;
}

.aboutDescription ul {
    color: rgb(99, 99, 184);
    text-align: justify;
}

.aboutDescription ul li span {
    font-size: medium;
    color: grey;
    opacity: 0.9;
    font-weight: 300;
}

.aboutDescription ul li b {
    opacity: 0.7;
}

.paddingSetFac {
    margin: 0 10px;
}

@media screen and (max-width:1199px) {
    .FacilitiesBtn {
        width: 50% !important;
    }
}

@media screen and (max-width:767px) {
    .FacilitiesBtn {
        width: 30% !important;
        margin: 10px 0;
    }

    .boxFacilities {
        margin: 5px 0 !important;
    }

    .FacilitiesPaddingRemove {
        padding: 0 !important;
    }
}

@media screen and (max-width:600px) {

    .FacilitiesPaddingRemove {
        padding: 4px 20px !important;
    }

}

@media screen and (max-width:480px) {
    .paddingSetFac {
        margin: initial !important;

    }

    .FacilitiesPaddingRemove {
        padding: 4px 10px !important;
    }

    .FacilitiesBtn {
        width: 50% !important;
        margin: 10px 0;
    }

    .boxFacilities {
        padding: 4px 15px !important;
    }

    .FacilitiesTitle h5 {
        font-size: medium;
    }

    .FacilitiesDescription {
        font-size: small;
        font-weight: 500;
        opacity: 0.8;
    }


}

@media screen and (max-width:400px) {

    .logoProcessFa {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        margin: 0 5px;
        width: 40px;
        border-radius: 50%;
        background-color: white;
    }

    .logoProcessFa span .logoSizeFac {
        font-size: 15px !important;
    }

    .boxFacilities {
        padding: 2px 10px !important;
    }

    .FacilitiesTitle h5 {
        font-size: small;
    }

    .FacilitiesDescription {
        font-size: xx-small;
        font-weight: 500;
        opacity: 0.8;
    }
}