.service {
    background-color: white;
    height: 100%;
    width: 100%;

}

.serviceHeading h5 {
    color: rgb(91, 202, 186);
    font-weight: 900;
}

.serviceSubHeading h1 {
    font-weight: 700;
}

.box {
    padding: 10px;
    border-radius: 8px;
    background-color: rgb(237, 249, 247);
    transition: 0.3s ease-in-out;
}

.box .logo span {
    color: rgb(141, 236, 224);
    font-size: 50px;
}

.box .heading h5 {
    color: rgb(31, 87, 162);
    font-weight: 600;
}

.box .SubHeading p {
    color: grey;
    opacity: 0.9;
}

.readMore span {
    color: rgb(31, 87, 162);
    display: hidden;

}

.box:hover {
    background-color: rgb(246, 252, 252);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.box:hover .logo span {
    color: rgb(31, 87, 162);
}

.box:hover .readMore span {
    display: block;
}

@media screen and (max-width:525px) {
    .space {
        margin: 8px 0;
        padding: 5px 0;
    }

    .box {
        padding: 5px;
        border-radius: 5px;
    }

    .box .logo span {
        font-size: 40px;
    }

    .box .heading h5 {
        font-weight: 700;
    }

    .box .SubHeading p {
        color: grey;
        opacity: 0.7;
        font-size: medium;
        font-weight: 600;
    }

}

@media screen and (max-width:575px) {
    .box {
        width: 90%;
        margin: 10px auto !important;

    }


}