.about {
    background-color: rgb(246, 252, 252) !important;
    height: 100%;
    width: 100%;
    padding: 90px 0;
}

.aboutHeading h5 {
    color: rgb(61, 203, 182);
}

.aboutSubheading h1 {
    font-weight: 700 !important;
}

.aboutDescription p {
    opacity: 0.8;
    text-align: justify;
}

.AboutPoint {
    font-weight: 500;
    color: grey;
    opacity: 0.9;
}

/* .rightShift {
    display: flex !important;
    flex-direction: row !important;
    justify-content: right !important;
} */

.imgContainer {
    position: absolute;
    height: 70%;
    width: 40% !important;
    z-index: 99999999 !important;


}

.imgContainer img {
    height: 80%;
    width: 100%;
}

.upperAbout {
    position: relative;
    background-color: rgb(61, 203, 182);
    height: 200px;
    width: 90%;
    left: -15px !important;
    bottom: -65% !important;

}

.lowerAbout {
    position: relative;
    background-color: rgb(168, 192, 228);
    height: 200px;
    left: 110px !important;
    top: -55%;
}

@media screen and (max-width:991px) {

    /* .aboutSubheading h1 br {
        display: none;
    } */
    .about {

        padding: 30px 0;
    }


    .aboutSubheading h1 {
        font-weight: 800 !important;
        font-size: x-large;
    }

    .aboutDescription p {
        opacity: 0.8;
        text-align: justify;
        font-size: small;
    }

    .AboutPoint {
        font-weight: 400;
        color: grey;
        opacity: 0.9;
        font-size: small;

    }

    .aboutBtnSmall {
        font-size: small;
    }
}

@media screen and (max-width:767px) {
    .noneAtSmallAbout {
        display: none;
    }

    .about {

        padding: 40px 0;
    }

    .aboutSubheading h1 {
        font-weight: 800 !important;
        font-size: xx-large;
    }

    .aboutDescription p {
        opacity: 0.8;
        text-align: justify;
        font-size: medium;
    }

    .aboutBtnSmall {
        font-size: medium;
    }


}

@media screen and (max-width:425px) {
    .aboutSubheading h1 {
        font-weight: 700 !important;
        font-size: x-large;
    }

    .aboutDescription p {
        opacity: 0.8;
        text-align: justify;
        font-size: small;
    }

    .aboutBtnSmall {
        font-size: medium;
    }

    .aboutBtnSmall {
        font-size: small;
    }
}

@media screen and (min-width:1345px) {
    .imgContainer img {
        height: 70%;
        width: 100%;
    }
}

@media screen and (min-width:1494px) {
    .imgContainer img {
        height: 60%;
        width: 100%;
    }
}

@media screen and (min-width:2281px) {
    .imgContainer img {
        height: 55%;
        width: 100%;
    }
}

@media screen and (min-width:2501px) {
    .imgContainer img {
        height: 50%;
        width: 100%;
    }
}