.review {
    height: auto;
    width: 100%;
    background-color: rgb(246, 252, 252) !important;
}

.reviewSection {
    width: 100%;
    height: 75%;
    /* color: rgb(255, 187, 0); */
}

.testimonial {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 18px;
    background: #dbebee;
    border-radius: 14px;
    padding: 3rem 3rem;


}

.descriptionReview p {
    text-align: justify;
    font-size: medium !important;
    word-break: break-all;
    color: black;
    font-weight: 500;
    opacity: 0.8;
}


.imgREview {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgREview img {
    height: 100%;
    width: 100%;
}

.detailsPatient {
    text-align: justify;
    line-height: 0;
}

.detailsPatient h6 {
    color: rgb(31, 87, 162);
}

.detailsPatient p {
    color: grey;
    opacity: 0.9;
    font-weight: 300;
    font-size: small;

}



@media screen and (max-width:991px) {
    .swiper-slide {

        font-size: 10px;

        padding: 1rem;


    }

    .descriptionReview p {
        font-size: x-small;
        font-weight: 400;
        opacity: 0.8;
    }

}

@media screen and (max-width:767px) {
    .reviewSection {
        width: 100%;
        height: 75%;
    }

    .reviewSmallPadding {
        padding: 0 !important;
    }

    .marginRemove {
        margin: 0 !important;
    }
}

@media screen and (max-width:425px) {
    .reviewSection {
        width: 100%;
        height: 85%;
    }

    .descriptionReview p {
        font-size: xx-small;
        font-weight: 400;
        opacity: 0.8;
    }

    .swiper-slide {

        font-size: 1px;

        padding: 1rem;


    }
}