.Hero {
    height: auto;
    width: 100%;
    overflow: hidden !important;
    background-image: linear-gradient(to right, rgb(56, 105, 122), rgb(100, 173, 190));
}

.heroColor p {
    color: white;
    font-weight: 600;
}

.heroColor h1 {
    color: white;
    font-weight: 500;
}

.heroColor .description {
    font-weight: 400;
    opacity: 0.7;
}

.btnHero {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.Appointment {
    background-color: rgb(61, 203, 182);
    color: white;
    font-weight: 500;
}


.Appointment:hover {
    background-color: rgb(12, 240, 206) !important;
    color: white !important;
}

.styleCallBtn {
    font-size: medium !important;
    font-weight: 300 !important;
}

.styleCallBtn:hover {
    color: white !important;
}

.callNow {
    outline: 1px solid white;
    background-color: transparent;
    color: white;
    font-weight: 500;
}

.callNow:hover {

    color: rgba(255, 255, 255, 0.897);

}

/* .callNow {
    --color: #0077ff;
    font-family: inherit;
    display: inline-block;
    width: 6em;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    margin: 20px;
    font-size: 17px;
    z-index: 1;
    color: var(--color);
    border: 2px solid var(--color);
    border-radius: 6px;
    position: relative;
}

.callNow::before {
    position: absolute;
    content: "";
    background: var(--color);
    width: 150px;
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

.callNow:hover {
    color: white;
}

.callNow:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

.callNow:hover::before {
    top: -30px;
    left: -30px;
} */














.imgDoctor {
    margin-top: -14px;
}

.imgDoctor .firstDoctor {
    height: 500px;
}

.imgDoctor .secondDoctor {
    display: none;
}

@media screen and (max-width:1183px) {
    .atSmall {
        margin-top: 2px !important;
    }

}

@media screen and (max-width:991px) {
    .imgDoctor .firstDoctor {
        height: 400px;
    }

    .imgDoctor {
        margin-top: 30px;
    }
}

@media screen and (max-width:767px) {
    .Hero {
        height: auto !important;

    }

    .imgDoctor .firstDoctor {
        display: none;
    }

    .imgDoctor {

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .imgDoctor .secondDoctor {
        display: block;

    }
}

@media screen and (max-width:525px) {

    .row.heroColor h1 br {
        display: none;

    }

    .Hero {
        height: 100vh;

    }

    .heroColor p {
        font-weight: 700;
    }

    .heroColor h1 {
        font-weight: 800;
        font-size: 2rem;
    }

    .heroColor .description {
        font-weight: 400;
        opacity: 0.7;
    }

    .imgDoctor .secondDoctor {
        height: 300px;
    }
}

@media screen and (max-width:387px) {

    .btnHero a {
        font-size: small;
        padding: 5px 5px;
    }
}

@media screen and (max-width:359px) {

    .Appointment {
        font-weight: 500;
        font-size: smaller;
        padding: 7px 5px !important;
    }

    .Appointment:hover {
        background-color: rgb(12, 240, 206);
        color: white;
    }

    .callNow {
        font-size: smaller;
        font-weight: 500;
    }




}

@media screen and (max-width:344px) {
    .btnHero {
        display: flex;
        gap: 10px;
        flex-direction: column !important;
    }
}