* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

.fixIcon {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 9999999999999;
  bottom: 10px;
  right: 15px
}

.icon1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: aqua;
  margin: 15px 0;

}

::-webkit-scrollbar {
  display: none;
}